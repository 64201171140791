var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"40f934bdfc39e8dec035f9f6cb14d9915457f7da"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
// eslint-disable-next-line unicorn/prefer-node-protocol
import process from 'process'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
	dsn:
		SENTRY_DSN ||
		'https://62a15cb7bcdc0fe1c8f0ee957010f16e@o4505745869635584.ingest.sentry.io/4505745894604800',
	// Adjust this value in production, or use tracesSampler for greater control
	enabled: process.env.VERCEL !== undefined,
	release: process.env.VERCEL_GIT_COMMIT_SHA,
	tracesSampleRate: process.env.VERCEL === undefined ? 0 : 1,
	// ...
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
})
