import 'focus-visible'
import '~/styles/tailwind.css'

import type { AppType } from 'next/dist/shared/lib/utils'
import type { Session } from 'next-auth'
import { GlobalCanvas } from '@14islands/r3f-scroll-rig'
import * as Sentry from '@sentry/nextjs'
import { Analytics } from '@vercel/analytics/react'
import clsx from 'clsx'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { SessionProvider, useSession } from 'next-auth/react'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import React, { useEffect } from 'react'

import { inter, jbMono, sfProDisplay, sfProText } from '~/components/tailwind'
import { trpc } from '~/utils/trpc'

const SentryUser = () => {
	const { data } = useSession()
	useEffect(() => {
		if (data?.user?.id) {
			Sentry.setUser({ email: data.user.email, id: data.user.id })
		}
	}, [data?.user?.id])
	return null
}

const App: AppType<{ session?: Session }> = ({
	Component,
	pageProps: { session, ...pageProps },
}) => {
	const router = useRouter()
	const canonicalUrl = (
		`https://messagedecoder.com` + (router.asPath === '/' ? '' : router.asPath)
	).split('?')[0]
	return (
		<>
			<Head>
				<link rel="canonical" href={canonicalUrl} key="canonical" />
			</Head>
			<main
				className={clsx(
					sfProDisplay.variable,
					sfProText.variable,
					inter.variable,
					jbMono.variable,
					'font-sans'
				)}
			>
				<GlobalCanvas
					style={{
						pointerEvents: 'none',
						zIndex: 1,
					}}
				/>
				<SessionProvider session={session}>
					<Component {...pageProps} />
					<SentryUser />
				</SessionProvider>
				<Analytics />
				{/* relies on `NEXT_PUBLIC_GA_MEASUREMENT_ID` ENVVAR */}
				<GoogleAnalytics trackPageViews />
			</main>
		</>
	)
}

export default trpc.withTRPC(App)
