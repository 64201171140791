import type { inferReactQueryProcedureOptions } from '@trpc/react-query'
import type { inferRouterInputs } from '@trpc/server'
import { httpBatchLink } from '@trpc/client'
import { createTRPCNext } from '@trpc/next'
import superjson from 'superjson'

import type { AppRouter } from '~/server/routers/_app'

export type ReactQueryOptions = inferReactQueryProcedureOptions<AppRouter>
export type RouterInputs = inferRouterInputs<AppRouter>

function getBaseUrl() {
	if (typeof window !== 'undefined')
		// browser should use relative path
		return ''

	if (process.env.VERCEL_URL)
		// reference for vercel.com
		return `https://${process.env.VERCEL_URL}`

	// assume localhost
	return `http://localhost:${process.env.PORT ?? 3000}`
}

export const trpc = createTRPCNext<AppRouter>({
	config() {
		return {
			links: [
				httpBatchLink({
					url: `${getBaseUrl()}/api/trpc`,
				}),
			],
			transformer: superjson,
		}
	},
	ssr: true,
})
