const colors = require('tailwindcss/colors')

/** @type {import('tailwindcss').Config} */
module.exports = {
	content: [
		// app content
		`src/**/*.{js,ts,jsx,tsx,mdx}`,
		`public/**/*.html`,
		// include packages if not transpiling
		// "../../packages/**/*.{js,ts,jsx,tsx}",
	],
	plugins: [require('@tailwindcss/typography')],
	theme: {
		extend: {
			colors: {
				primary: colors.amber,
			},
		},
	},
}
