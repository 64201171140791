import { Inter, JetBrains_Mono } from 'next/font/google'
import localFont from 'next/font/local'
import resolveConfig from 'tailwindcss/resolveConfig'

import tailwindConfig from '../../tailwind.config.js'

const tailwind = resolveConfig(tailwindConfig)

export default tailwind

export const jbMono = JetBrains_Mono({
	display: 'swap',
	fallback: [
		'ui-monospace',
		'SFMono-Regular',
		'Menlo',
		'Monaco',
		'Consolas',
		'"Liberation Mono"',
		'"Courier New"',
		'monospace',
	],
	subsets: ['latin'],
	variable: '--font-jb-mono',
})

export const inter = Inter({
	display: 'swap',
	fallback: [
		'ui-sans-serif',
		'system-ui',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'"Noto Sans"',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
		'"Noto Color Emoji"',
	],
	subsets: ['latin'],
	variable: '--font-inter',
})

export const sfProDisplay = localFont({
	display: 'swap',
	fallback: [
		'var(--font-sf-pro-text)',
		'var(--font-inter)',
		'ui-sans-serif',
		'system-ui',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'"Noto Sans"',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
		'"Noto Color Emoji"',
	],
	src: [
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_bold.woff2',
			style: 'normal',
			weight: '700',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_light.woff2',
			style: 'normal',
			weight: '300',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_medium.woff2',
			style: 'normal',
			weight: '500',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_semibold.woff2',
			style: 'normal',
			weight: '600',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_thin.woff2',
			style: 'normal',
			weight: '200',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_ultralight.woff2',
			style: 'normal',
			weight: '100',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Display/sf-pro-display_regular.woff2',
			style: 'normal',
			weight: '400',
		},
	],
	variable: '--font-sf-pro-display',
})

export const sfProText = localFont({
	display: 'swap',
	fallback: [
		'var(--font-inter)',
		'ui-sans-serif',
		'system-ui',
		'-apple-system',
		'BlinkMacSystemFont',
		'"Segoe UI"',
		'Roboto',
		'"Helvetica Neue"',
		'Arial',
		'"Noto Sans"',
		'sans-serif',
		'"Apple Color Emoji"',
		'"Segoe UI Emoji"',
		'"Segoe UI Symbol"',
		'"Noto Color Emoji"',
	],
	src: [
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Text/sf-pro-text_thin.woff2',
			style: 'normal',
			weight: '200',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Text/sf-pro-text_regular.woff2',
			style: 'normal',
			weight: '400',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Text/sf-pro-text_regular-italic.woff2',
			style: 'italic',
			weight: '400',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Text/sf-pro-text_semibold.woff2',
			style: 'normal',
			weight: '600',
		},
		{
			path: '../../public/fonts/sf-pro/SF-Pro-Text/sf-pro-text_bold.woff2',
			style: 'normal',
			weight: '700',
		},
	],
	variable: '--font-sf-pro-text',
})
